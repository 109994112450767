import React from 'react'
import { Link } from "gatsby"

const ContactText = () => (
    <section id="_contact" className="section contact">
    <div className="container">
          <h6 className="text">
          <span>Are you talented and resourceful? Do you always strive to create value wherever you go? We would 
          like to hear from you.</span>
          We’re always reaching out to find the best talent possible, when team building is concerned we are 
          greatly pleased with our results. A unique professional work environment with great knowledge of 
          technological processes.

          </h6>
          <div className="button-area">
            <Link to="/contact-us" className="button green">CONTACT US TO GET STARTED</Link>
          </div>
      </div>
  </section>
)

export default ContactText