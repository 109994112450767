import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/process_and_operational_excellence.jpg'
import person from '../../images/person.png'
import ContactText from '../../components/ContactText'

const pageTitle = "Operational and Process Excellence";
const pageSLug = "operational-and-process-excellence";
class OperationalProcess extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Together, we can improve operational processes and procedures which 
            will allow your organization to meet and surpass competitive challenges.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Business processes have become critical factors in allowing businesses to perform 
                    at high levels and succeed in the marketplace.
                    </h3>
                    <p>The right people are crucial, proper support mechanisms are necessary, and technology can help. 
                        Processes are fundamental mechanisms that allow businesses to deliver value. At Nuerex, we have 
                        experience with process excellence and capabilities.</p>
                    <span className="break"></span>
                    <h4>Process Excellence: A Strategic Differentiator</h4>
                    <p>Process and operational excellence can radically change the game when it comes to solving many 
                        competitive challenges that companies are facing today. By effectively leveraging processes, 
                        companies can gain an advantage in addressing opportunities and threats that have emerged in the 
                        marketplace. Allowing themselves to be uniquely positioned and able to deliver unparalleled value 
                        to customers.</p>
                    <p>Competition in the marketplace is radically evolving, and this requires highly flexible nimble 
                        Business models. Agility is becoming ever more critical. Efficient business processes that are 
                        enabled and supported by flexible technology solutions can give you a competitive advantage.</p>
                    <p>A valuable competitive differentiator – Process excellence, is both a source of customer 
                        satisfaction and a critical factor in financial performance.</p>
                    <p>Technology is changing a wide array of industries in a way that has never been seen before — 
                        requiring companies to re-evaluate their business model and processes across their 
                        entire value chain.</p>
                    <p>Talent acquisition is intensifying, and this results in talent becoming more difficult to attain. 
                        This leads to issues in keeping a reliable resource base and retaining capital in knowledge. 
                        Therefore processes are looked upon to provide a predictable and reliable source of performance.</p>
                    <p>IT is being used to supplement Business effectiveness and efficiency. Process excellence initiatives 
                        are a top priority for organizations that use IT, as they improve effectiveness and 
                        efficiency while reducing costs.</p>
                    <p>Would you rather customers and employees be free to work on complex problems,  instead of 
                        being overloaded with manually intensive tasks? Robotic Process Automation (RBA) 
                        can make that a reality.</p>
                    <span className="break"></span>
                    <h4>Operational Excellence Solutions</h4>
                    <span className="break"></span>
                    <h5>Sourcing and Procurement</h5>
                    <p>Sourcing and Procurement are crucial to ensuring low overhead costs and efficient workflow. 
                        Our approach allows for a balanced arrangement between direct saving initiatives and building 
                        world-class sustainable procurement capabilities within a company's processes, technology 
                        solutions, and organizational structures.</p>
                    <span className="break"></span>
                    <h5>Supply Chain Operations</h5>
                    <p>By implementing strategic supply chain operations and strategies, companies get on the 
                        path to sustained improvement. Our Supply Chain service focusing  on a core set of capabilities, 
                        including:</p>
                    <ul>
                        <li>Distribution</li>
                        <li>Manufacturing</li>
                        <li>Customer Service and Segmentation</li>
                        <li>Supply Chain Planning and Optimization</li>
                    </ul>
                    <span className="break"></span>
                    <h5>Our Process Excellence Approach</h5>
                    <p>Our operational and process excellence approach will drive measurable results and build 
                        sustainable capability while enabling ongoing performance improvements.</p>
                    <span className="break"></span>
                    <div className="contact-box">
                    <h3>Not sure where to start, our team is always ready to help.</h3>
                        <span className="break"></span>
                        <div className="button-area">
                            <Link to="/contact-us" className="button default">Talk with a professional</Link>
                        </div>
                    </div>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>

      <section className="section static-page page-services">
          <div className="container">
              <div className="page-title-area">
                  <h2 className="title">Process Excellence Services</h2>
                  <h6 className="subtitle">Each of our services complement each other to drive, enable, sustain, and provide improvements 
                  across all levels of the organization. Learn more today</h6>
              </div>
              <div className="columns is-multiline">
                  <div className="single column is-3">
                      <Link to={"/business-consulting/"+pageSLug+"/process-and-capability-assessment"}  className="inner">
                          <span className="line"></span>
                        <h2 className="title">Process and Capability Assessment</h2>
                        <p className="desc">Our experts can identify process improvement opportunities based on organizational 
                        readiness and business value.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/business-consulting/"+pageSLug+"/business-process-improvement"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Business Process Improvement (BPI)</h2>
                        <p className="desc">Delivering measurable processes as well as operational results and improvements.</p>
                      </Link>
                  </div>
                  
                  <div className="single column is-3">
                      <Link to={"/business-consulting/"+pageSLug+"/performance-measurement"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Performance Measurement</h2>
                        <p className="desc">Measuring and driving ongoing performance improvement with 
                        aligned metrics, reports, and balanced scorecards.</p>
                      </Link>
                  </div>

              </div>
          </div>
      </section>

     <ContactText />

      </Layout>
    )
  }
}

export default OperationalProcess
